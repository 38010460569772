/* eslint-disable import/prefer-default-export */
export const optionsOrder = (t) => [
    {
        aclCode: 'oms_lite_header_sales',
        key: 'order',
        label: t('menu:menuOrder:parent'),
        children: [
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_all',
                label: t('menu:menuOrder:allOrders'),
                url: '/order/allorder',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_allocating',
                label: t('menu:menuOrder:allocating'),
                url: '/order/allocating',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_failed',
                label: t('menu:menuOrder:failed'),
                url: '/order/failed',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_order_processing',
                label: t('menu:menuOrder:orderProcessing'),
                url: '/order/order_processing',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_shipment_processing',
                label: t('menu:menuOrder:shipmentProcessing'),
                url: '/order/shipment_processing',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_complete',
                label: t('menu:menuOrder:completed'),
                url: '/order/complete',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_canceled',
                label: t('menu:menuOrder:canceled'),
                url: '/order/canceled',
            },
            {
                aclCode: 'sales_order_invoice',
                key: 'orderqueue_invoice',
                label: t('menu:menuOrder:invoice'),
                url: '/order/invoice',
            },
            {
                aclCode: 'sales_order_queue_bulk_tools',
                key: 'orderqueue_bulk_tools',
                label: t('menu:menuOrder:bulkTools'),
                url: '/order/bulktools',
            },
            // { aclCode: 'orderreallocation', label: 'Order Reallocation', url: '/sales/orderreallocation' },
        ],
    },
];
